body {
    font-family: 'Montserrat';
    font-size: 1.4rem;
    font-weight: 500;
}

.container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
}

.logo {
    display: block;
    width: 100%;
    max-width: 150px;
    margin: 0 0 20px 0;
}

.title {
    font-weight: 900;
    line-height: 1;

    &--h1 {
        margin: 0 0 25px 0;
        font-size: 2.6rem;

        @include desktop {
            font-size: 3.2rem;
        }
    }
}

.col {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 20px;
    min-height: 100vh;

    &__item {
        width: 100%;
        padding: 30px 0;

        &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @include desktop {
            width: calc(50% - 10px);
        }
    }
}

.form {
    width: 100%;

    &__device {
        margin: 20px 0 0 0;

        label {
            display: block;
        }

        select {
            margin: 10px auto 0 0;
            padding: 5px;
            border: 2px solid color(noir);
            border-radius: 5px;
            font-family: inherit;
            font-size: 1.4rem;
            font-weight: 500;
        }
    }

    &__label {
        width: 20%;
    }

    &__input {
        display: block;
        flex: 1 0 auto;
        padding: 10px 25px;
        border: 2px solid color(noir);
        border-radius: 5px;
        background: color(blanc);
        color: color(noir);
        font-size: 1.4rem;
        line-height: 1;
    }

    &__item {
        display: flex;
        align-items: center;
        column-gap: 20px;
        margin: 10px 0 0 0;
    }

    &__submit {
        margin: 10px auto 0;
        cursor: pointer;
    }
}

.iframe {
    margin: 20px 0;
    border: 2px solid color(noir);
    border-radius: 5px;

    &__copy {
        display: block;
        padding: 10px 25px;
        border: 2px solid color(noir);
        border-radius: 5px;
        background: color(blanc);
        color: color(noir);
        font-size: 1.4rem;
        line-height: 1;
        cursor: pointer;
    }

    &__mobile {
        display: none;
        margin: 20px 0;
        padding: 15px;
        border: 2px solid color(noir);
        border-radius: 5px;
    }
}

.text-container {
    height: calc(100vh - 60px);
    overflow-y: auto;

    @include tablette {
        height: auto;
    }

    & > * {
        &:not(:first-child) {
            margin: 20px 0 0 0;
        }
    }

    p {
        a {
            color: color(noir);
            text-decoration: underline;
        }
    }
}