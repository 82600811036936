@import 'the-color';

// Colors
$color-variations: (
    noir: #000000,
    blanc: #ffffff,

    social: (
        /**
         * Brand colors
         *
         * @see https://brandcolors.net/
         */
        defaut: #9f9f9f,
        facebook: #3b5998,
        twitter: #1da1f2,
        google-plus: #dd4b39,
        linkedin: #0077b5,
        vimeo: #1ab7ea,
        youtube: #cd201f,
        instagram: unquote('radial-gradient(circle at 30% 100%, #fed276, rgba(253, 189, 97, 0.5) 9%, #f6804d 20%, rgba(232, 61, 92, 0)), linear-gradient(166deg, #4e60d3, #913baf 14%, #d52d88 76%, #f26d4f)')
    )
);

$custom_ease: cubic-bezier(0.37, 0.75, 0, 1);

@font-face {
    font-family: 'Montserrat';
    src: url('../../fonts/montserrat-medium.woff2') format('woff2'),
       url('../../fonts/montserrat-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../fonts/montserrat-black.woff2') format('woff2'),
       url('../../fonts/montserrat-black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}