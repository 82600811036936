* {
    margin: 0px;
    padding: 0px;
    border: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

img {
    border: none;
    display: block;
    outline: none;
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
    outline: none;
    border: none;
}

ul, li {
    list-style-type: none;
}

textarea {
    resize: vertical;
}

header, footer, nav, section, article {
    display: block;
    position: relative;
}

html {
    font-size: 10px;
    overflow-y: auto;
}

body {
    line-height: normal;
    width: 100%;
    height: auto;
    overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

input, textarea, select, option {
    outline: none !important;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}