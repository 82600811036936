@charset "UTF-8";
/**
 * Get the color
 *
 * Get color from $color-variations map, inspired by WordPress and his the_title/get_the_title functions.
 * 
 * Thanks to Hugo Giraudel, as usual, master of Sass. 😊

 * @access  public
 * @author  Jérémy Levron <levronjeremy@19h47.fr>
 * @edited for Couleur Citron  Benjamin Costes <https://twitter.com/benjam1nC>
 */
@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/montserrat-medium.woff2") format("woff2"), url("../../fonts/montserrat-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/montserrat-black.woff2") format("woff2"), url("../../fonts/montserrat-black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
* {
  margin: 0px;
  padding: 0px;
  border: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

img {
  border: none;
  display: block;
  outline: none;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  outline: none;
  border: none;
}

ul, li {
  list-style-type: none;
}

textarea {
  resize: vertical;
}

header, footer, nav, section, article {
  display: block;
  position: relative;
}

html {
  font-size: 10px;
  overflow-y: auto;
}

body {
  line-height: normal;
  width: 100%;
  height: auto;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

input, textarea, select, option {
  outline: none !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

body {
  font-family: "Montserrat";
  font-size: 1.4rem;
  font-weight: 500;
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  display: block;
  width: 100%;
  max-width: 150px;
  margin: 0 0 20px 0;
}

.title {
  font-weight: 900;
  line-height: 1;
}
.title--h1 {
  margin: 0 0 25px 0;
  font-size: 2.6rem;
}
@media only screen and (min-width: 1024px) {
  .title--h1 {
    font-size: 3.2rem;
  }
}

.col {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;
  min-height: 100vh;
}
.col__item {
  width: 100%;
  padding: 30px 0;
}
.col__item:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 1024px) {
  .col__item {
    width: calc(50% - 10px);
  }
}

.form {
  width: 100%;
}
.form__device {
  margin: 20px 0 0 0;
}
.form__device label {
  display: block;
}
.form__device select {
  margin: 10px auto 0 0;
  padding: 5px;
  border: 2px solid #000000;
  border-radius: 5px;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
}
.form__label {
  width: 20%;
}
.form__input {
  display: block;
  flex: 1 0 auto;
  padding: 10px 25px;
  border: 2px solid #000000;
  border-radius: 5px;
  background: #ffffff;
  color: #000000;
  font-size: 1.4rem;
  line-height: 1;
}
.form__item {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin: 10px 0 0 0;
}
.form__submit {
  margin: 10px auto 0;
  cursor: pointer;
}

.iframe {
  margin: 20px 0;
  border: 2px solid #000000;
  border-radius: 5px;
}
.iframe__copy {
  display: block;
  padding: 10px 25px;
  border: 2px solid #000000;
  border-radius: 5px;
  background: #ffffff;
  color: #000000;
  font-size: 1.4rem;
  line-height: 1;
  cursor: pointer;
}
.iframe__mobile {
  display: none;
  margin: 20px 0;
  padding: 15px;
  border: 2px solid #000000;
  border-radius: 5px;
}

.text-container {
  height: calc(100vh - 60px);
  overflow-y: auto;
}
@media only screen and (max-width: 1024px) {
  .text-container {
    height: auto;
  }
}
.text-container > *:not(:first-child) {
  margin: 20px 0 0 0;
}
.text-container p a {
  color: #000000;
  text-decoration: underline;
}